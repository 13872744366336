import {
  FrequentFlyerNumber,
  ICorpPerson,
  IFlyerOption,
  IPerson,
} from "redmond";
import { IHotelLoyaltyProgram } from "./hotelLoyaltyProgramList";

const getNewHotelLoyalty = (
  traveler: IPerson,
  loyaltyProgram: IHotelLoyaltyProgram | null,
  newLoyaltyNumber?: string
): { [key: string]: any } | undefined => {
  if (newLoyaltyNumber === undefined || !loyaltyProgram) {
    return undefined;
  }

  const newHotelLoyalty = { ...traveler.hotelLoyalty };

  if (newLoyaltyNumber === "") {
    delete newHotelLoyalty[loyaltyProgram.loyaltyProgramCode];
  } else {
    newHotelLoyalty[loyaltyProgram.loyaltyProgramCode] = {
      value: newLoyaltyNumber,
    };
  }

  return newHotelLoyalty;
};

const getNewFrequentFlyerNumber = (
  traveler: IPerson,
  frequentFlyerPrograms: IFlyerOption[] | null,
  newFlyerNumber?: FrequentFlyerNumber
): { [key: string]: any } | undefined => {
  if (newFlyerNumber === undefined || !frequentFlyerPrograms) {
    return undefined;
  }

  const newFrequentFlyer = { ...traveler.frequentFlyer };

  frequentFlyerPrograms.forEach((frequentFlyerProgram) => {
    if (newFlyerNumber === "") {
      delete newFrequentFlyer[frequentFlyerProgram.program];
    } else {
      newFrequentFlyer[frequentFlyerProgram.program] = {
        value: newFlyerNumber,
      };
    }
  });

  return newFrequentFlyer;
};

export const getUpdatedTraveler = (
  traveler: IPerson,
  loyaltyProgram: IHotelLoyaltyProgram | null,
  frequentFlyerPrograms: IFlyerOption[] | null,
  newLoyaltyNumber?: string,
  newEmail?: string,
  newFlyerNumber?: FrequentFlyerNumber
) => {
  const newHotelLoyalty = getNewHotelLoyalty(
    traveler,
    loyaltyProgram,
    newLoyaltyNumber
  );

  const newFrequentFlyerNumber = getNewFrequentFlyerNumber(
    traveler,
    frequentFlyerPrograms,
    newFlyerNumber
  );

  const updatedTraveler = {
    ...traveler,
    ...(newHotelLoyalty && { hotelLoyalty: newHotelLoyalty }),
    ...(newEmail && { emailAddress: newEmail }),
    ...(newFrequentFlyerNumber && { frequentFlyer: newFrequentFlyerNumber }),
  } satisfies IPerson | ICorpPerson;

  return updatedTraveler;
};
