import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState, useMemo } from "react";
import { RouterProps } from "react-router";
import { useInView } from "react-intersection-observer";
import Skeleton from "react-loading-skeleton";
import clsx from "clsx";

import {
  ActionButton,
  Header,
  PageTabNavigations,
  useDeviceTypes,
} from "halifax";

import { ExperiencesShopConnectorProps } from "./container";
import "./styles.scss";
import { RewardsAccountSelection } from "../rewards/components";
import {
  BACK_TO_RESULTS,
  CANCELLATION_POLICY,
  COMPARE_OTHER_SIMILAR_EXPERIENCES,
  CONTACT_INFORMATION,
  CUSTOMER_REVIEWS,
  SHOP_DETAIL_HEADER_TEXT,
  TRAVELERS_PHOTOS,
  WHAT_TO_EXPECT,
  WHATS_INCLUDED,
} from "./textConstants";
import { TravelWalletDrawer } from "../travel-wallet/components";

import { ShopPhotoGallery, PhotoGroup } from "./components/ShopPhotoGallery";
import { ShopOverview } from "./components/ShopOverview/component";
import { ShopWhatToExpect } from "./components/ShopWhatToExpect/component";
import { ShopCancellationPolicy } from "./components/ShopCancellationPolicy/component";
import { ShopWhatsIncluded } from "./components/ShopWhatsIncluded/component";
import { ShopCustomerReviews } from "./components/ShopCustomerReviews/component";
import { ShopContactInformation } from "./components/ShopContactInformation/component";
import { ShopCheckoutBreakdown } from "./components/ShopCheckoutBreakdown/component";
import { ShopEarnInfo } from "./components/ShopEarnInfo/component";
import { ShopCompare } from "./components/ShopCompare/component";
import { ExperiencesShopNavTab, SHOP_NAV_TABS } from "./constants";
import { scrollToSection } from "../common";
import { ExperiencesShopReviewsSort } from "redmond";

export interface IExperiencesShop
  extends ExperiencesShopConnectorProps,
    RouterProps {}

export const ExperiencesShop = (props: IExperiencesShop) => {
  const {
    history,
    searchLocation,
    experienceName,
    experienceProviderName,
    shopOverview,
    isLoading,
    fetchExperiencesShop,
    shopWhatsIncluded,
    shopContactInfo,
    shopWhatToExpect,
    setFromDate,
    setUntilDate,
    fromDate,
    untilDate,
    shopGallery,
    fetchExperiencesShopReviews,
    selectedExperienceId,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  const [selectedSectionId, setSelectedSectionId] = useState<string>();
  const [gallerySectionRef, gallerySectionInView] = useInView({
    threshold: 0.8,
  });
  const [overviewSectionRef, overviewSectionInView] = useInView({
    threshold: 0.8,
  });
  const [expectSectionRef, expectSectionInView] = useInView({ threshold: 0.8 });
  const [cancelSectionRef, cancelSectionInView] = useInView({ threshold: 0.8 });
  const [includedSectionRef, includedSectionInView] = useInView({
    threshold: 0.8,
  });
  const [reviewSectionRef, reviewSectionInView] = useInView({ threshold: 0.8 });
  const [travelerPhotoSectionRef, travelerPhotoSectionInView] = useInView({
    threshold: 0.8,
  });
  const [contactSectionRef, contactSectionInView] = useInView({
    threshold: 0.8,
  });
  const [compareSectionRef, compareSectionInView] = useInView({
    threshold: 0.8,
  });

  const selectedTabIndex = useMemo(() => {
    if (
      gallerySectionInView &&
      (selectedSectionId
        ? selectedSectionId === ExperiencesShopNavTab.GALLERY
        : true)
    ) {
      return 0;
    }
    if (
      overviewSectionInView &&
      (selectedSectionId
        ? selectedSectionId === ExperiencesShopNavTab.OVERVIEW
        : true)
    ) {
      return 1;
    }
    if (
      expectSectionInView &&
      (selectedSectionId
        ? selectedSectionId === ExperiencesShopNavTab.WHAT_TO_EXPECT
        : true)
    ) {
      return 2;
    }
    if (
      cancelSectionInView &&
      (selectedSectionId
        ? selectedSectionId === ExperiencesShopNavTab.CANCELLATION_POLICY
        : true)
    ) {
      return 3;
    }

    if (
      includedSectionInView &&
      (selectedSectionId
        ? selectedSectionId === ExperiencesShopNavTab.WHATS_INCLUDED
        : true)
    ) {
      return 4;
    }

    if (
      reviewSectionInView &&
      (selectedSectionId
        ? selectedSectionId === ExperiencesShopNavTab.REVIEWS
        : true)
    ) {
      return 5;
    }
    if (
      travelerPhotoSectionInView &&
      (selectedSectionId
        ? selectedSectionId === ExperiencesShopNavTab.TRAVELER_PHOTOS
        : true)
    ) {
      return 6;
    }
    if (
      contactSectionInView &&
      (selectedSectionId
        ? selectedSectionId === ExperiencesShopNavTab.CONTACT
        : true)
    ) {
      return 7;
    }
    if (
      compareSectionInView &&
      (selectedSectionId
        ? selectedSectionId === ExperiencesShopNavTab.COMPARE
        : true)
    ) {
      return 8;
    }

    return undefined;
  }, [
    gallerySectionInView,
    overviewSectionInView,
    expectSectionInView,
    cancelSectionInView,
    includedSectionInView,
    reviewSectionInView,
    travelerPhotoSectionInView,
    contactSectionInView,
    compareSectionInView,
    selectedSectionId,
  ]);

  const tabs = SHOP_NAV_TABS.map((nav) => ({
    label: nav,
    onClick: () => {
      setSelectedSectionId(nav);
      // clearing out selectedSectionId to resume naturally updated nav tab selection as user scroll
      // 1 sec to allow scroll behavior to finish
      setTimeout(() => {
        setSelectedSectionId(undefined);
      }, 1000);
      scrollToSection(nav);
    },
  }));

  // TODO clean up
  const isSkeleton = true;

  useEffect(() => {
    fetchExperiencesShop({
      history: history,
    });
  }, []);

  useEffect(() => {
    if (selectedExperienceId) {
      fetchExperiencesShopReviews({
        experienceId: selectedExperienceId,
        refinement: {
          sort: ExperiencesShopReviewsSort.TopReviews,
        },
        start: 1,
        count: 10,
      });
    }
  }, [selectedExperienceId]);

  const renderDesktopHeaderView = () => {
    return (
      <Box className="experiences-shop-container">
        <Header
          className="rewards-components-section"
          left={
            <Box className={"rewards-account-section-left-content"}>
              <ActionButton
                className="back-to-results"
                message={BACK_TO_RESULTS}
                onClick={() => {
                  // returnToAvailability();
                }}
                defaultStyle="h4r-secondary"
              />
              <Box className={"rewards-account-section-travel-details"}>
                <Typography variant={"body1"} tabIndex={0}>
                  {SHOP_DETAIL_HEADER_TEXT(
                    experienceName ? experienceName : "",
                    searchLocation ? searchLocation.label : ""
                  )}
                </Typography>
                <Typography variant={"body2"} tabIndex={0}>
                  {experienceProviderName}
                </Typography>
              </Box>
            </Box>
          }
          right={
            <Box className="desktop-experiences-shop-rewards-account-contents">
              <RewardsAccountSelection
                className={clsx("b2b hide-balance-border")}
                popoverClassName="b2b"
              />
              <TravelWalletDrawer />
            </Box>
          }
        />
        <div
          ref={gallerySectionRef}
          id={ExperiencesShopNavTab.GALLERY}
          className="experiences-shop-gallery-container"
        >
          <ShopPhotoGallery isSkeleton={isLoading} {...shopGallery} />
        </div>

        <PageTabNavigations
          navItems={tabs}
          selectedTab={selectedTabIndex}
          className="experiences-shop-navigation"
        />

        <Box className="experiences-shop-split-view-container">
          <Box className="experiences-shop-split-view-section">
            <Box className="experiences-shop-left-section">
              <div
                ref={overviewSectionRef}
                id={ExperiencesShopNavTab.OVERVIEW}
                className="experiences-shop-section-container"
              >
                <Typography className="experiences-shop-component-title">
                  {shopOverview?.title}
                </Typography>
                <ShopOverview
                  fromDate={fromDate}
                  untilDate={untilDate}
                  isSkeleton={isSkeleton}
                  {...shopOverview}
                  setFromDate={setFromDate}
                  setUntilDate={setUntilDate}
                  fetchExperiencesShop={() => {
                    fetchExperiencesShop({ history });
                  }}
                />
              </div>
              <div
                ref={expectSectionRef}
                id={ExperiencesShopNavTab.WHAT_TO_EXPECT}
                className="experiences-shop-section-container"
              >
                <Typography className="experiences-shop-component-title">
                  {WHAT_TO_EXPECT}
                </Typography>
                <ShopWhatToExpect
                  isSkeleton={isSkeleton}
                  {...shopWhatToExpect}
                />
              </div>
              <div
                ref={cancelSectionRef}
                id={ExperiencesShopNavTab.CANCELLATION_POLICY}
                className="experiences-shop-section-container"
              >
                <Typography className="experiences-shop-component-title">
                  {CANCELLATION_POLICY}
                </Typography>
                <ShopCancellationPolicy isSkeleton={isSkeleton} />
              </div>
              <div
                ref={includedSectionRef}
                id={ExperiencesShopNavTab.WHATS_INCLUDED}
                className="experiences-shop-section-container"
              >
                <Typography className="experiences-shop-component-title">
                  {WHATS_INCLUDED}
                </Typography>
                <ShopWhatsIncluded
                  isSkeleton={isLoading}
                  {...shopWhatsIncluded}
                />
              </div>
              <div
                ref={reviewSectionRef}
                id={ExperiencesShopNavTab.REVIEWS}
                className="experiences-shop-section-container"
              >
                <Typography className="experiences-shop-component-title">
                  {CUSTOMER_REVIEWS}
                </Typography>
                <ShopCustomerReviews isSkeleton={isSkeleton} />
              </div>
              <div
                ref={travelerPhotoSectionRef}
                id={ExperiencesShopNavTab.TRAVELER_PHOTOS}
                className="experiences-shop-section-container"
              >
                <Typography className="experiences-shop-component-title">
                  {TRAVELERS_PHOTOS}{" "}
                  {shopGallery?.travelerPhotos?.length
                    ? ` (${shopGallery.travelerPhotos.length})`
                    : null}
                </Typography>
                <ShopPhotoGallery
                  isSkeleton={isLoading}
                  {...shopGallery}
                  selectedGroup={PhotoGroup.TRAVELER}
                />
              </div>
              <div
                ref={contactSectionRef}
                id={ExperiencesShopNavTab.CONTACT}
                className="experiences-shop-section-container"
              >
                <Typography className="experiences-shop-component-title">
                  {CONTACT_INFORMATION}
                </Typography>
                <ShopContactInformation
                  isSkeleton={isSkeleton}
                  {...shopContactInfo}
                />
              </div>
            </Box>
            <Box className="experiences-shop-right-section">
              <ShopCheckoutBreakdown isSkeleton={isSkeleton} />
              <ShopEarnInfo isSkeleton={isSkeleton} />
            </Box>
          </Box>
          <div
            ref={compareSectionRef}
            id={ExperiencesShopNavTab.COMPARE}
            className="experiences-compare-section-container"
          >
            <Typography className="experiences-shop-component-title">
              {COMPARE_OTHER_SIMILAR_EXPERIENCES}
            </Typography>
            <ShopCompare isSkeleton={isSkeleton} />
          </div>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box
        className={clsx("experiences-shop-root", {
          "full-width": !matchesMobile,
        })}
      >
        {renderDesktopHeaderView()}
        <Box className="experiences-shop-container"></Box>
      </Box>
    </>
  );
};

export interface IShopComponentSkeletonProps {
  name: string;
}
export const ShopComponentSkeleton = (props: IShopComponentSkeletonProps) => {
  return (
    <Skeleton className={clsx("experiences-shop-skeleton", `${props.name}`)} />
  );
};
