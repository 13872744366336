import { createContext, useContext } from "react";
import {
  CorpSessionInfo,
  CorporateInfo,
  PolicyTier,
  RewardsAccount,
  SessionInfo,
  Tenant,
  UserCorpPermissions,
} from "redmond";
import { isCorpTenant } from "../utils/getTenant";

export interface UserInfoContext<T extends SessionInfo | CorpSessionInfo> {
  sessionInfo: T;
  updateSessionInfo: (userInfo: T) => void;
  isBusinessEligible: boolean;
  updateBusinessEligibility: (eligible: boolean) => void;
  policies: PolicyTier;
  setPolicies: (policies: PolicyTier) => void;
  logo: {
    src: string;
    alt: string;
  };
  isMultiAccount: boolean;
  eligibleBusinessAccounts: RewardsAccount[];
  setEligibleBusinessAccounts: (accounts: RewardsAccount[]) => void;
}

type UserContextByTenant<T extends Tenant> = Partial<
  UserInfoContext<T extends "capone" ? SessionInfo : CorpSessionInfo>
>;

export const userContextDefaultValues = {
  sessionInfo: {
    userInfo: { firstName: "", lastName: "", email: "" },
    isFirstSession: false,
    csrfToken: "",
    sessionExpiration: "",
    isDelegatedSession: "",
    userId: "",
    corporateInfo: {
      permissions: {} as UserCorpPermissions,
      businessIsLive: false,
      role: [] as CorporateInfo["role"],
      policyTier: "",
      businessId: "",
      businessName: "",
      accountReferenceId: "",
      businessLoyaltyPrograms: undefined,
      defaultTravelerId: "",
      hiddenTravelerIds: [],
      hasSeenModalMap: { sean_ellis: false, vxb_helptip: false },
      cap1Role: "",
    },
  },
  updateSessionInfo: () => undefined,
  isBusinessEligible: false,
  isMulitAccount: false,
  eligibleBusinessAccounts: [],
  setEligibleBusinessAccounts: () => undefined,
};

export const UserContext = createContext<UserContextByTenant<"capone">>(
  userContextDefaultValues
);

export const CorpUserContext = createContext<
  UserContextByTenant<"capone-corporate">
>(userContextDefaultValues);

export const useUserContext = <T extends Tenant = "capone">(
  tenant: Tenant = "capone"
): UserContextByTenant<T> => {
  return isCorpTenant(tenant)
    ? (useContext(CorpUserContext) as UserContextByTenant<T>)
    : (useContext(UserContext) as UserContextByTenant<T>);
};
