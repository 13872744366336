import React from "react";
import { DesktopDateDetailsBannerConnectorProps } from "./container";
import { Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import "./styles.scss";
import {
  Icon,
  IconName,
  BannerSeverity,
  NotificationBanner,
} from "halifax";
import * as textConstants from "./textConstants";

export interface IDesktopDateDetailsBannerProps
  extends DesktopDateDetailsBannerConnectorProps,
    RouteComponentProps {
    isUnavailable?: boolean;
}

export const DesktopDateDetailsBanner = ({
  isUnavailable,
}: IDesktopDateDetailsBannerProps) => {
  return (
    <NotificationBanner
      className="pc-date-details-banner"
      content={
        <Typography className="pc-date-details-text">
          {isUnavailable ? textConstants.ERROR_BANNER : textConstants.TEXT_BANNER}
        </Typography>
      }
      severity={BannerSeverity.INFO}
      icon={<Icon className="banner-icon" name={IconName.InfoCircle} />}
    />
  );
};
