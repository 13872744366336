import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { DesktopDateDetailsBanner } from "./component";
const mapStateToProps = () => ({
});

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopDateDetailsBannerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedDesktopDateDetailsBanner = withRouter(connector(DesktopDateDetailsBanner));
